<template>
  <b-modal
    v-model="_state"
    size="lg"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @show="resetData"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <template v-slot:modal-title>
      <h5 class="text-secondary mb-0">
        {{ $t("page.condominium.assignMaintainer") }}
      </h5>
    </template>
    <template v-slot:default>
      <h6>{{ category.description }}</h6>
      <form-select
        v-model="selectedMaintainer"
        :veeRules="{ required: true, select: true }"
      >
        <b-form-select-option :value="null" disabled>
          {{ $t("page.condominium.selectMaintainerMsg") }}
        </b-form-select-option>
        <b-form-select-option
          v-for="(maintainer, index) in maintainers"
          :key="index"
          :value="maintainer"
        >
          {{ maintainer.profile.maintainerId.name }}
        </b-form-select-option>
      </form-select>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-dark" @click="cancel">
        {{ $t("button.cancel") }}
      </b-button>
      <b-button
        class="text-white"
        variant="primary"
        :disabled="!isMaintainerSelected"
        @click="ok"
      >
        {{ $t("button.add") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import FormSelect from "../Base/BaseFormSelect";
export default {
  components: {
    FormSelect
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedMaintainer: null
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    maintainers() {
      return this.$store.getters["condominium/getCondominiumMaintainers"];
    },
    isMaintainerSelected() {
      return this.selectedMaintainer !== null;
    }
  },
  methods: {
    setModalState(value) {
      this.$emit("update:state", value);
    },
    resetData() {
      this.selectedMaintainer = null;
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onSubmit() {
      this.$emit("onSubmit", this.selectedMaintainer.profile.maintainerId._id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
