<template>
  <div>
    <h3 class="mb-5">
      <span class="text-secondary">{{ $t("condominium") }} - </span>
      <span v-if="!activeEdit">{{ condominium.name }}</span>
      <b-icon
        v-if="!activeEdit"
        icon="pencil-square"
        variant="primary"
        class="pl-2"
        style="cursor: pointer;"
        @click="activateEditName"
      ></b-icon>
      <span v-if="activeEdit">
        <b-form-input
          size="sm"
          style="max-width: 15em"
          v-model="condominiumName"
        ></b-form-input>
        <b-button
          pill
          size="sm"
          class="text-white mr-2"
          @click="updateCondominium"
        >
          {{ $t("button.save") }}
        </b-button>
        <b-button pill size="sm" class="text-white" @click="activeEdit = false">
          {{ $t("button.cancel") }}
        </b-button>
      </span>
    </h3>
    <div class="row no-gutters ml-n3 mb-5">
      <div
        v-for="(metrics, index) in metricsList"
        :key="index"
        class="col-12 col-sm-3 pl-3 mb-3 mb-3 mb-sm-0"
      >
        <metrics-card
          :total="metrics.total"
          :description="metrics.description"
          :bgIcon="metrics.icon"
          :bgColor="metrics.bgColor"
        />
      </div>
    </div>
    <b-tabs
      v-model="activeTab"
      content-class="mt-5"
      active-nav-item-class="font-weight-bold"
      no-key-nav
    >
      <b-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :title="$t(`page.condominium.${tab.title}`)"
        title-link-class="text-dark"
        :lazy="lazyTabs[index]"
        @click="updateTabLazyState"
      >
        <component :is="tab.component" />
      </b-tab>
    </b-tabs>
    <edit-condominium
      :condominium="condominium"
      v-model="condominiumDialog"
      @update="updateCondominium"
    ></edit-condominium>
  </div>
</template>

<script>
import appMixin from "../mixins/appMixin";

import MetricsCard from "./../components/Base/MetricsCard";
import UsersTab from "./../components/Condominium/UsersTab";
import IssuesTab from "./../components/Condominium/IssuesTab";
import AssignmentsTab from "./../components/Condominium/AssignmentsTab";
import CommunicationsTab from "./../components/Condominium/CommunicationsTab";
import EditCondominium from "./../components/Condominium/EditCondominium";
export default {
  components: {
    MetricsCard,
    UsersTab,
    IssuesTab,
    CommunicationsTab,
    AssignmentsTab,
    EditCondominium
  },
  mixins: [appMixin],
  data() {
    return {
      defaultTabs: [
        { title: "users", component: UsersTab },
        { title: "issues", component: IssuesTab },
        { title: "communications", component: CommunicationsTab }
      ],
      lazyTabs: { 0: true, 1: true, 2: true, 3: true },
      activeEdit: false,
      condominiumName: "",
      condominiumDialog: false
    };
  },
  computed: {
    tabs() {
      return this.$is1Board && this.condominium.status !== "free"
        ? [
            ...this.defaultTabs,
            { title: "assignments", component: AssignmentsTab }
          ]
        : this.defaultTabs;
    },
    condominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    activeTab: {
      get() {
        return this.$store.getters["condominium/getActiveTab"];
      },
      set(value) {
        this.$store.commit("condominium/SET_ACTIVE_TAB", value);
      }
    },
    user() {
      return this.$store.getters["auth/getAdminInfo"];
    },
    metricsList() {
      const metrics = this.$store.getters["condominium/getMetrics"];
      return [
        {
          total: metrics.pendingIssuesNum,
          description: "metrics.pendingIssues",
          icon: "tools",
          bgColor: "gray-chateau"
        },
        {
          total: metrics.communicationsNum,
          description: "metrics.communications",
          icon: "chat-left-text-fill",
          bgColor: "cyan"
        },
        {
          total: metrics.activeUsersNum,
          description: "metrics.subjects",
          icon: "people-fill",
          bgColor: "cyan-400"
        },
        {
          total: metrics.appUsers,
          description: "metrics.appUsers",
          icon: "phone",
          bgColor: "cyan-500"
        }
      ];
    }
  },
  methods: {
    updateTabLazyState() {
      this.lazyTabs[this.activeTab] = false;
    },
    activateEditName() {
      if (this.user.type === "domi") {
        this.condominiumDialog = true;
      } else {
        this.condominiumName = this.condominium.name;
        this.activeEdit = true;
      }
    },
    async updateCondominium(condominium) {
      if (
        this.condominiumName !== this.condominium.name &&
        this.condominiumName.length > 3
      ) {
        try {
          this.$store.commit("loader/SET_LOADER", {});
          await this.$store.dispatch("condominium/updateCondominium", {
            condominiumId: this.condominium._id,
            payload: {
              name: this.condominiumName,
              address: this.condominium.address,
              city: this.condominium.city,
              province: this.condominium.province,
              zip: this.condominium.zip,
              status: this.condominium.status
            }
          });

          await this.$store.dispatch(
            "condominium/retrieveCondominium",
            this.condominium._id
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.activeEdit = false;
          this.$store.commit("loader/RESET");
        }
      } else {
        this.activeEdit = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
