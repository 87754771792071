<template>
  <div>
    <b-table
      :items="issues"
      :fields="fields"
      :sort-by.sync="_sortBy"
      :sort-desc.sync="_sortDesc"
      responsive="sm"
      outlined
      hover
      show-empty
      small
      v-bind="$attrs"
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === _sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(condominiumName)="data">
        <span class="font-weight-bold">
          {{ data.value }}
        </span>
      </template>
      <template #cell(createdAt)="data">
        <span class="text-capitalize">
          {{ data.value | date("DD MMMM YYYY, HH:mm") }}
        </span>
      </template>
      <template #cell(title)="data">
        <span>
          <b-icon style="width: 12px" :icon="icons[data.item.type]" />
          {{ data.value }}
        </span>
        <span v-if="data.item.totalPhotos > 0" class="ml-3">
          <b-icon
            class="clickable"
            icon="camera-fill"
            @click="openGallery(data.item.photos)"
          />
        </span>
      </template>
      <template #cell(status)="data">
        <b-badge
          class="text-white font-weight-normal"
          :variant="statusVariant[data.value]"
        >
          {{ $t(`page.issue.status.${data.value}`) }}
        </b-badge>
      </template>
      <template #cell(totalComments)="data">
        {{ data.value }}
      </template>
      <template #cell(maintainer)="data">
        <span
          :class="{ 'clickable link': hasMaintainer(data.value) }"
          @click="
            hasMaintainer(data.value) && openMaintainerDetails(data.value)
          "
        >
          {{ getMaintainerName(data.value) }}
        </span>
      </template>
      <template #cell(reported)="data">
        <div class="text-center">
          <b-icon
            v-if="data.item.reported"
            variant="danger"
            icon="exclamation-triangle"
          ></b-icon>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-row class="no-gutters justify-content-end">
          <b-col
            v-for="(action, index) in actions"
            :key="index"
            class="col-auto"
          >
            <b-button
              size="sm"
              variant="secondary"
              class="ml-3"
              @click="action.callbackFunc(data.item.id)"
            >
              {{ $t(action.label) }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>
    <light-box
      v-if="photos.length > 0"
      ref="lightbox"
      :media="photos"
      :show-light-box="false"
    />
    <maintainer-details
      :modalState.sync="maintainerDetailsModalState"
      :maintainer="selectedMaintainer"
      :isAssociateBtnVisible="false"
    />
  </div>
</template>

<script>
import { ISSUE_TYPE_ICONS, ISSUE_STATUS_VARIANTS } from "./../../Utils";

import MaintainerDetails from "./../Maintainer/MaintainerDetails";
import LightBox from "vue-image-lightbox";
export default {
  components: {
    MaintainerDetails,
    LightBox
  },
  props: {
    issues: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusVariant: ISSUE_STATUS_VARIANTS,
      icons: ISSUE_TYPE_ICONS,
      photos: [],
      selectedMaintainer: null,
      maintainerDetailsModalState: false
    };
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = true;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    hasMaintainer(maintainer) {
      return maintainer._id;
    },
    getMaintainerName(maintainer) {
      return maintainer ? maintainer.name : "-";
    },
    async openMaintainerDetails(maintainer) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("userMaintainer/retrieveMaintainerUser", {
          userId: maintainer.userId
        });
        this.selectedMaintainer = this.$store.getters[
          "userMaintainer/getCurrentMaintainerUser"
        ].generalInfo;
        this.maintainerDetailsModalState = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    openGallery(photos) {
      let photoSrc;
      if (photos.length !== this.photos.length) this.photos = [];

      this.$nextTick(() => {
        this.photos = photos.map(photo => {
          photoSrc = this.getPhotoSrc(photo);
          return {
            thumb: photoSrc,
            src: photoSrc
          };
        });
        this.$nextTick(() => {
          this.$refs.lightbox.showImage(0);
        });
      });
    },
    getPhotoSrc(photo) {
      return photo.url;
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
.link:hover {
  text-decoration: underline;
  color: var(--primary);
}
</style>
