<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.issue.newIssue") }}
      </h5>
    </template>
    <template v-slot:default>
      <file-uploader
        ref="issuePhotos"
        :multiple="true"
        @setFiles="setIssuePhotos"
      />
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <form-select
            v-model="issue.condominium"
            :label="'page.issue.condominiumLabel'"
            :disabled="condominium ? true : false"
            veeName="page.issue.condominiumLabel"
            :veeRules="{ required: true, select: true }"
          >
            <b-form-select-option :value="null" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(condominium, index) in condominiumOptions"
              :key="index"
              :value="condominium"
            >
              {{ condominium.name }}
            </b-form-select-option>
          </form-select>
          <form-select
            v-model="issue.type"
            :label="'page.issue.typeLabel'"
            veeName="page.issue.typeLabel"
            :veeRules="{ required: true, select: true }"
          >
            <b-form-select-option :value="''" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(type, index) in typeOptions"
              :key="index"
              :value="type"
            >
              <span v-if="$is1Board">
                {{ type.description }}
              </span>
              <span v-else>
                {{ $t(`fixCategories.${type}`) }}
              </span>
            </b-form-select-option>
          </form-select>
          <form-input
            v-model="issue.title"
            label="page.issue.titleLabel"
            :placeholder="$t('page.issue.titlePlacehoder')"
            veeName="page.issue.titleLabel"
            :veeRules="{ required: true }"
          />
          <form-textarea
            v-model="issue.description"
            rows="6"
            label="page.issue.descriptionLabel"
            :placeholder="$t('page.issue.descriptionPlaceholder')"
            veeName="page.issue.descriptionLabel"
            :veeRules="{ required: true }"
          />
          <div class="font-weight-bold mb-3">
            {{ $t("page.issue.addPhotos") }}
          </div>
          <b-row class="no-gutters image-container">
            <img
              class="clickable mr-2"
              style="width: 60px"
              src="./../../assets/add-photo.svg"
              @click="openUploadDialog"
            />
            <div
              v-for="(photo, index) in issue.photos"
              :key="index"
              class="image-wrapper mr-3"
            >
              <b-img :src="photo.src" class="image-item" />
              <b-icon
                class="custom-badge clickable"
                icon="trash-fill"
                color="white"
                @click="removePhoto(photo)"
              />
            </div>
          </b-row>

          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button variant="outline-dark" class="mr-3" @click="closeModal">
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="createIssue"
            >
              {{ $t("button.insert") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import FormTextarea from "../Base/BaseFormTextarea";
import FormSelect from "../Base/BaseFormSelect";
import FileUploader from "../Base/BaseFileUploader";
import uniqueId from "lodash/uniqueId";
import { appMixin } from "../../mixins";
export default {
  props: {
    state: { type: Boolean, default: false },
    condominium: { type: [Object, null], default: null },
    callbackAfterCreate: { type: Function, default: () => {} }
  },
  components: { FormInput, FormTextarea, FormSelect, FileUploader },
  mixins: [appMixin],
  data() {
    return {
      issue: {
        type: "",
        title: "",
        description: "",
        photos: [],
        condominium: null
      }
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    },
    typeOptions() {
      if (this.$is1Board)
        return this.$store.getters["parameters/getActiveCategories"];
      return this.$store.getters["issue/getTypeOptions"];
    },
    condominiumOptions() {
      return this.$store.getters["condominium/getActiveCondominiums"];
    }
  },
  methods: {
    resetForm() {
      this.issue = {
        type: "",
        title: "",
        description: "",
        photos: [],
        condominium: null
      };
      if (this.condominium) {
        this.issue.condominium = this.condominiumOptions.find(
          c => c._id === this.condominium._id
        );
      }
    },
    openUploadDialog() {
      this.$refs.issuePhotos.openSelectFileWindow();
    },
    setIssuePhotos(files) {
      if (!files) return;
      // TBD: check the type of the uploaded files
      // if (!this.isImage(files[0].type)) {
      //   this.$store.commit("alert/SET_ALERT", {
      //     messageText: "invalidFileFormat.text"
      //   });
      //   return;
      // }
      files.forEach(file => {
        this.issue.photos.unshift({
          _id: uniqueId("local_"),
          file: file,
          src: URL.createObjectURL(file)
        });
      });
    },
    isImage(type) {
      const containerFormat = type.split("/");
      return containerFormat[0] ? containerFormat[0] === "image" : false;
    },
    removePhoto(val) {
      const index = this.issue.photos
        .map(item => {
          return item._id;
        })
        .indexOf(val._id);
      this.issue.photos.splice(index, 1);
    },
    async createIssue() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        console.log({
          condominiumId: this.issue.condominium._id,
          issueDetails: this.getIssuePayload()
        });
        await this.$store.dispatch("issue/createIssue", {
          condominiumId: this.issue.condominium._id,
          issueDetails: this.getIssuePayload()
        });
        await this.callbackAfterCreate();
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getIssuePayload() {
      const issueDetails = {
        title: this.issue.title,
        description: this.issue.description,
        type: this.$is1Board ? this.issue.type._id : this.issue.type
      };
      if (this.issue.photos.length > 0) {
        const photoFiles = [];
        this.issue.photos.forEach(item => {
          photoFiles.unshift(item.file);
        });
        issueDetails.photos = photoFiles;
      }

      return issueDetails;
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
}
.image-wrapper {
  position: relative;
}
.image-item {
  border-radius: 10px;
  width: 60px;
  height: 100%;
}
.custom-badge {
  position: absolute;
  font-size: 20px;
  padding: 4px;
  bottom: 0px;
  right: -10px;
  background: var(--red);
  border-radius: 25px;
}
</style>
