<template>
  <div class="body-container col">
    <span
      :class="`body-container ${expanded ? '' : 'truncated'}`"
      v-html="_value"
    ></span>
    <span>
      <b-button
        v-if="hasMore"
        style="padding: 0;"
        variant="link"
        @click="expanded = !expanded"
      >
        {{ expanded ? $t("readLess") : $t("readMore") }}</b-button
      >
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    _value() {
      return this.sanitizedHtml(this.value);
    },
    hasMore() {
      return this.value.length > 500;
    }
  },
  methods: {
    sanitizedHtml(html) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return html.replace(urlRegex, url => {
        const newUrl = url.replace(/<[/]*\w+>/g, "");
        return `<a target="_blank" title="${newUrl}" href="${newUrl}">${url.slice(
          0,
          30
        )}${url.length > 30 ? "..." : ""}</a>`;
      });
    }
  }
};
</script>

<style scoped>
.truncated {
  overflow: hidden;
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>
