import { render, staticRenderFns } from "./CreateCondominiumUser.vue?vue&type=template&id=77867420&scoped=true&"
import script from "./CreateCondominiumUser.vue?vue&type=script&lang=js&"
export * from "./CreateCondominiumUser.vue?vue&type=script&lang=js&"
import style0 from "./CreateCondominiumUser.vue?vue&type=style&index=0&id=77867420&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77867420",
  null
  
)

export default component.exports