<template>
  <div>
    <assignment-table
      :assignments="formatedCategories"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    />

    <assign-maintainer-modal
      :state.sync="assignCategoryModalState"
      :category="selectedCategory"
      @onSubmit="assignCategory"
    />
  </div>
</template>

<script>
import { appMixin, userMixin } from "../../mixins";

import AssignmentTable from "../Assignment/AssignmentTable";
import AssignMaintainerModal from "../Assignment/AssignMaintainerModal";
export default {
  components: {
    AssignmentTable,
    AssignMaintainerModal
  },
  mixins: [appMixin, userMixin],
  data() {
    return {
      dataLoaded: false,
      assignCategoryModalState: false,
      selectedCategory: {},
      selectedCategoryId: null,
      fields: [
        { key: "description", label: "category", sort: true },
        { key: "maintainerName", label: "nameAndSurname", sort: true },
        { key: "maintainerCompany", label: "companyName", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.openAssignCategoryModal
        }
      ],
      sortBy: "description",
      sortDesc: false
    };
  },
  created() {
    this.$nextTick(() => {
      this.retrieveCategories();
    });
  },
  computed: {
    condominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    condominiumCategories() {
      return this.$store.getters["condominium/getCondominiumCategories"];
    },
    formatedCategories() {
      if (this.dataLoaded && this.condominiumCategories.length > 0) {
        return this.condominiumCategories.map(item => {
          return {
            id: item._id,
            description: item.description || "-",
            maintainerName: this.getMaintainerFullName(item.assignedTo),
            maintainerCompany: this.getMaintainerCompanyName(item.assignedTo),
            email: this.getMaintainerEmail(item.assignedTo)
          };
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    getMaintainerFullName(assignedTo) {
      return assignedTo
        ? this.$_getUserFullName(assignedTo.maintainerId.userId.profile)
        : "-";
    },
    getMaintainerCompanyName(assignedTo) {
      return assignedTo ? assignedTo.maintainerId.name || "-" : "";
    },
    getMaintainerEmail(assignedTo) {
      return assignedTo ? assignedTo.maintainerId.email || "-" : "";
    },
    async retrieveCategories() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch(
          "condominium/retrieveCondominiumCategories",
          { administratorId: this.condominium.ownerId }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.dataLoaded = true;
        this.$store.commit("loader/RESET");
      }
    },
    async openAssignCategoryModal(categoryId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch(
          "condominium/retrieveCondominiumMaintainers",
          {
            administratorId: this.condominium.ownerId,
            type: categoryId
          }
        );
        this.selectedCategoryId = categoryId;
        this.selectedCategory = this.formatedCategories.find(
          ({ id }) => id === categoryId
        );
        this.assignCategoryModalState = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async assignCategory(maintainerId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("parameters/assignCategory", {
          categoryId: this.selectedCategoryId,
          payload: {
            condominiumId: this.condominium._id,
            maintainersIds: [maintainerId]
          }
        });

        await this.$store.dispatch(
          "condominium/retrieveCondominiumCategories",
          { administratorId: this.condominium.ownerId }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
