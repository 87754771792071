<template>
  <div>
    <b-row class="no-gutters justify-content-end">
      <b-button
        class="mb-3 mr-1"
        size="md"
        variant="secondary"
        :disabled="selectedUsers.length === 0"
        @click="openInviteConfirmationModal"
      >
        {{ $t("button.invite") }}
      </b-button>
      <b-button
        class="mb-3 mr-1"
        size="md"
        variant="secondary"
        @click="openCreateUserModal"
      >
        {{ $t("button.createUser") }}
      </b-button>
      <b-button
        class="mb-3"
        size="md"
        variant="secondary"
        @click="openImportModal"
      >
        {{ $t("button.importUsers") }}
      </b-button>
    </b-row>
    <user-table
      :users="formatedUsers"
      :fields="fields"
      :actions="actions"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :selectable="true"
      @setSelectedItems="setSelectedUsers"
    />

    <import-users :state.sync="importModalState" />
    <user-details v-if="detailsModalState" :state.sync="detailsModalState" />
    <create-condominium-user :state.sync="createUserModalState" />
  </div>
</template>

<script>
import userMixin from "./../../mixins/userMixin";

import ImportUsers from "./ImportUsers";
import UserTable from "../User/UserTable";
import UserDetails from "../User/UserDetails";
import CreateCondominiumUser from "../Condominium/CreateCondominiumUser";
export default {
  components: {
    ImportUsers,
    UserTable,
    UserDetails,
    CreateCondominiumUser
  },
  mixins: [userMixin],
  data() {
    return {
      dataLoaded: false,
      importModalState: false,
      detailsModalState: false,
      createUserModalState: false,
      fields: [
        { key: "selected", label: "selected" },
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "telephone", label: "telephone", sort: false },
        { key: "status", label: "status", sort: false },
        { key: "role", label: "roleInCondominium", sort: false },
        { key: "registrationDate", label: "registrationDate", sort: true },
        { key: "actions", label: "" }
      ],
      actions: [
        {
          label: "button.manage",
          variant: "secondary",
          callbackFunc: this.openUserDetailsModal
        },
        {
          label: "button.delete",
          variant: "orange",
          callbackFunc: this.openDeleteConfirmationModal
        },
        {
          label: "button.suspend",
          variant: "secondary",
          hidden: user => user.status === "active",
          callbackFunc: this.suspendUser
        },
        {
          label: "button.reactivate",
          variant: "orange",
          hidden: user => user.status === "suspended",
          callbackFunc: this.reactivateUser
        }
      ],
      sortBy: "fullName",
      sortDesc: false,
      selectedUsers: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.retrieveUsers();
    });
  },
  computed: {
    currentCondominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    },
    condominiumUsers() {
      return this.$store.getters["condominium/getCondominiumUsers"];
    },
    formatedUsers() {
      if (this.dataLoaded && this.condominiumUsers.length > 0) {
        return this.condominiumUsers.map(item => {
          return {
            id: item._id,
            photo: this.$_getUserProfilePhoto(item.profile),
            fullName: this.$_getUserFullName(item.profile),
            email: this.getUserEmailByType(item.type, item.email),
            telephone: this.$_getUserTelephone(item.profile),
            role: this.getUserRole(item.condominiums),
            isOwner: this.isOwner(item._id),
            status: item.status,
            registrationDate: item.registrationDate
          };
        });
      } else return [];
    }
  },
  methods: {
    openImportModal() {
      this.importModalState = true;
    },
    openCreateUserModal() {
      this.createUserModalState = true;
    },
    openUserDetailsModal(userId) {
      const selectedUser = this.condominiumUsers.find(u => u._id === userId);
      this.$store.commit("user/SET_CURRENT_USER", selectedUser);
      this.detailsModalState = true;
    },
    openDeleteConfirmationModal(userId) {
      const user = this.condominiumUsers.find(u => u._id === userId);
      if (user.profile)
        var userName = `${user.profile?.name} ${user.profile?.surname}`;
      else userName = "";
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "confirm.removeUserTitle",
        messageText: "confirm.removeUserMessage",
        messageParams: { userName },
        confirmBtnCallback: this.deleteUser,
        confirmBtnParams: { userId: user._id },
        type: "DELETE"
      });
    },
    async deleteUser({ userId }) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/removeUserFromCondominium", {
          userId,
          condominiumId: this.currentCondominium._id
        });

        await this.$store.dispatch("condominium/retrieveCondominiumUsers");
        await this.$store.dispatch(
          "condominium/retrieveCondominium",
          this.currentCondominium._id
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    isOwner(userId) {
      return (
        this.currentCondominium.status === "free" &&
        this.currentCondominium.ownerId === userId
      );
    },
    getUserEmailByType(type, email) {
      return type === "guest" ? "-" : email;
    },
    getUserRole(condominiums) {
      return (
        condominiums.find(c => c.condominiumId === this.currentCondominium._id)
          ?.role || "-"
      );
    },
    async retrieveUsers() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/retrieveCondominiumUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.dataLoaded = true;
        this.$store.commit("loader/RESET");
      }
    },
    setSelectedUsers(items) {
      this.selectedUsers = items;
    },
    openInviteConfirmationModal() {
      this.$store.commit("confirm/SET_CONFIRM", {
        confirmBtnCallback: this.sendInvitations
      });
    },
    async sendInvitations() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        const payload = {
          recipients: [],
          sendPdf: false
        };
        payload.recipients = this.selectedUsers.map(user => {
          return user.id;
        });
        await this.$store.dispatch("condominium/inviteUsers", {
          condominiumId: this.currentCondominium._id,
          payload
        });
        await this.$store.dispatch("condominium/retrieveCondominiumUsers");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async suspendUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/suspendUser", userId);
        await this.retrieveUsers();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async reactivateUser(userId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/reactivateUser", userId);
        await this.retrieveUsers();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
